import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox']

  connect() {
    // need to reference the targets where `this` will be something else
    const self = this
    this.checkboxTargets.forEach((cbx) => {
      cbx.addEventListener('change', function() {
        const checkedCount = self.checkboxTargets.filter(function(c) { return c.checked }).length
        if(checkedCount > 0) {
          self.checkboxTargets.forEach((c2) => {
            c2.removeAttribute('required')
            // setCustomValidity is weird - need to set blank to make it not "required"
            c2.setCustomValidity('')
          })
        } else {
          self.checkboxTargets.forEach((c2) => {
            c2.setAttribute('required', 'required')
            c2.setCustomValidity('please check at least one')
          })
        }
      })
    })
  }
}
