import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  start(e) {
    const ppForm = this.element.closest('form')
    // if form is valid do the following:
    //   - prevent submission
    //   - show overlay
    //   - pause for 3 to 4 seconds
    //   - "continue" form submission
    if (ppForm.checkValidity()) {
      e.preventDefault();
      document.getElementById("spinner").classList.toggle('hidden')
      const pauseMs = this.generateRandomInteger(2000, 3000);
      setTimeout(function() {
        ppForm.submit()
      }, pauseMs)
    }
  }

  generateRandomInteger(min, max) {
    return Math.floor(min + Math.random()*(max - min + 1))
  }
}
