import { Controller } from '@hotwired/stimulus'
import Swiper from 'swiper/bundle';

export default class extends Controller {
  connect() {
    // init Swiper:
    const swiper = new Swiper(this.element, {
      autoplay: {
        delay: 8000
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '"></span>';
        }
      },
      allowTouchMove: false
    });
    //console.log(swiper)
  }
}


