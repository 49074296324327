import ahoy from 'ahoy.js'
ahoy.configure({
  visitsUrl: '/t/visits',
  eventsUrl: '/t/events',
})

/* Stimulus */
import { Application } from 'stimulus'
// import styles bundle
import 'swiper/css/bundle'

const application = Application.start()

import controllers from "../controllers/*_controller.js"
controllers.forEach((controller) => {
  var controllerName = controller.name.replace("..--controllers--", "")
  var controllerImpl = controller.module.default
  application.register(controllerName, controllerImpl)
})

import setupUltimateTurboModal from "ultimate_turbo_modal";
setupUltimateTurboModal(application);
